import axios from 'axios';
import authHeader from './auth-header';

const API_URL = window._env_.REACT_APP_API_HOST + '/v1/';

class AIService {
  postMessage(message = '', language = 'English', email = '') {
    return axios.post(API_URL + 'chat/assistant', { message, language, email }, { headers: authHeader() });
  }
}

export default new AIService();
