import React, { useState, useEffect, useRef } from 'react'
import './styles.css'
import { IconButton, TextField } from '@mui/material'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import utc from "dayjs/plugin/utc"
import { ThreeDot } from 'react-loading-indicators'
import aiService from 'src/services/ai.service'
import userService from 'src/services/user.service'
import ReactMarkdown from 'react-markdown';

const TypingAnimation = ({ text }) => {
  const [displayedText, setDisplayedText] = useState("");

  useEffect(() => {
    let index = 0;
    let currentText = "";

    const interval = setInterval(() => {
      if (index < text.length) {
        currentText += text[index] || '';
        setDisplayedText(currentText);
        index++;
        document.getElementById('chatRef').scrollTop = document.getElementById('chatRef').scrollHeight
      } else {
        clearInterval(interval);
      }
    }, 10);

    return () => clearInterval(interval);
  }, [text]);

  return <ReactMarkdown>{displayedText}</ReactMarkdown>;
};

const ChatMessage = ({ el }) => {
  return (
    el.type == 1 ?
      <div className={'chat-message chat-message-type-' + el.type}>
        <div className='chat-message-body'>
          <p>{el.text}</p>
        </div>
      </div>
      :
      <div className='row'>
        <img src="/images/chat-avatar.png" style={{ width: 58, height: 58, marginRight: 16 }} alt="" />
        <div className={'chat-message chat-message-type-' + el.type}>
          <div className='chat-message-body'>
            <p><TypingAnimation text={el.text} /></p>

          </div>
        </div>
      </div>
  )
}



const Chat = () => {
  dayjs.extend(utc)
  const { t } = useTranslation()
  const [input, setInput] = useState('')
  const [messages, setMessages] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [accountData, setAccountData] = useState(null)

  const chatRef = useRef(null);

  useEffect(() => {
    userService.getAccountData().then(resp => {
      setAccountData(resp.data)
    }).catch(err => {
      console.error(err);
    })
  }, [])

  useEffect(() => {
    if (chatRef.current) {
      chatRef.current.scrollTop = chatRef.current.scrollHeight;
    }
  }, [messages]);

  const submitForm = (e) => {
    e.preventDefault()
    setIsLoading(true)
    const language = accountData.language || 'English'
    const email = accountData?.email

    aiService.postMessage(input, language, email).then(resp => {
      setIsLoading(false)
      setMessages((prev) => [...prev, { type: 2, text: resp.data }])
    }).catch(err => {
      setIsLoading(false)
      console.error(err);
    })

    setMessages((prev) => [...prev, { type: 1, text: input.trim() }])
    setInput('')
  }

  const handleInput = (e) => {
    if (!e.nativeEvent.data && e.nativeEvent.inputType == "insertLineBreak") {
      submitForm(e)
    } else {
      setInput(e.target.value)
    }

  }

  return (
    <>
      <div style={{ maxWidth: '900px', margin: 'auto' }}>
        <div className='column'>
          {!messages.length && <h1 style={{ textAlign: 'center', marginTop: '12vh' }}>{t('Awareness Chat Room')} </h1>}
          <div className='chat-container' id="chatRef" ref={chatRef} style={{ height: messages.length ? 'calc(100vh - 280px)' : '0px', transition: 500 }}>
            <div className='chat-container-box column'>
              {messages?.map(el => <ChatMessage key={el.text} el={el} />)}

              {isLoading && <div className='column' style={{
                alignItems: 'flex-start',
                overflow: 'hidden'

              }}>
                <ThreeDot variant="bounce" color="#6123FF" size="small" text="" textColor="" />
              </div>}
            </div>
          </div>

          <form
            onSubmit={submitForm}
            className='column'
            style={{
              marginTop: '48px',
              position: 'relative',
              flex: 1,
              boxShadow: '0px 37px 81px 0px #00000003, 0px 148px 148px 0px #00000003, 0px 333px 200px 0px #00000003, 0px 593px 237px 0px #00000000, 0px 926px 259px 0px #00000000',
            }}
          >

            {/* <img
              src="/images/input-background.png"
              alt=""
              style={{
                position: 'absolute',
                top: '-310px',
                left: '-160px'
              }} /> */}




            <TextField
              sx={{ flex: 1, background: '#fff', '& .MuiInputBase-root': { borderRadius: '24px', padding: '28.5px 28px' } }}
              id="chat-input"
              placeholder={t("Enter your message")}
              multiline
              maxRows={4}
              value={input}
              onChange={handleInput}
            />

            <IconButton disabled={isLoading} onClick={submitForm} sx={{ position: 'absolute', bottom: '18px', right: '28px', background: input ? '#6123FF' : '#ECECF4', '&:hover': { background: input ? '#6123FFeb' : '#ECECF4eb' } }} >

              <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M14 23.1667V4.83333" stroke={input ? '#fff' : "#76767F"} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M6.5 12.3333L14 4.83333L21.5 12.3333" stroke={input ? '#fff' : "#76767F"} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              </svg>

            </IconButton>
          </form>

        </div>
      </div>
    </>
  )
}

export default Chat
