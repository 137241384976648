import React from 'react'

const ChatIcon = ({ light = false }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path opacity="0.2" d="M8.19922 17.063C8.57358 18.1258 9.17897 19.0923 9.97179 19.8929C10.7646 20.6936 11.7251 21.3084 12.7842 21.6932C13.8432 22.078 14.9744 22.2231 16.0962 22.1181C17.2181 22.0131 18.3027 21.6606 19.2719 21.086L19.2719 21.086L21.9021 21.8374C22.0109 21.8685 22.1261 21.87 22.2356 21.8416C22.3452 21.8132 22.4451 21.756 22.5252 21.676C22.6052 21.596 22.6623 21.496 22.6907 21.3864C22.7191 21.2769 22.7177 21.1617 22.6866 21.0529L21.9351 18.4228L21.9352 18.4228C22.6029 17.2967 22.9691 16.0176 22.9985 14.7088C23.0279 13.4 22.7195 12.1057 22.103 10.9508C21.4866 9.79591 20.5829 8.81938 19.4791 8.11543C18.3754 7.41148 17.1088 7.00387 15.8017 6.93193L15.8019 6.93185C16.2047 8.08034 16.3265 9.30859 16.1572 10.5138C15.9879 11.719 15.5324 12.8662 14.8289 13.8593C14.1253 14.8524 13.1941 15.6625 12.1132 16.2219C11.0323 16.7812 9.83311 17.0736 8.61605 17.0744C8.47619 17.0744 8.33725 17.0707 8.19922 17.0632V17.063Z" fill={light ? '#1B1B26' : 'white'} />
    <path d="M2.06524 13.3463C1.1191 11.7513 0.787753 9.86582 1.13342 8.04383C1.47909 6.22184 2.478 4.58871 3.94257 3.4511C5.40714 2.31349 7.23663 1.74966 9.0875 1.86549C10.9384 1.98131 12.6833 2.76882 13.9946 4.08014C15.3059 5.39145 16.0934 7.13637 16.2093 8.98724C16.3251 10.8381 15.7613 12.6676 14.6237 14.1322C13.4861 15.5968 11.853 16.5957 10.031 16.9413C8.20899 17.287 6.32348 16.9557 4.7285 16.0095L4.72852 16.0095L2.09835 16.7609C1.98954 16.792 1.87438 16.7935 1.76483 16.7651C1.65528 16.7367 1.55531 16.6795 1.47528 16.5995C1.39526 16.5195 1.33809 16.4195 1.3097 16.3099C1.28131 16.2004 1.28274 16.0852 1.31383 15.9764L2.06531 13.3463L2.06524 13.3463Z" stroke={light ? '#1B1B26' : 'white'} strokeLinecap="round" strokeLinejoin="round" />
    <path d="M8.19922 17.063C8.57358 18.1257 9.17897 19.0922 9.97179 19.8929C10.7646 20.6935 11.7251 21.3084 12.7842 21.6931C13.8432 22.0779 14.9744 22.223 16.0962 22.118C17.2181 22.013 18.3027 21.6606 19.2719 21.086L19.2719 21.0859L21.9021 21.8374C22.0109 21.8685 22.1261 21.8699 22.2356 21.8415C22.3452 21.8131 22.4451 21.7559 22.5252 21.6759C22.6052 21.5959 22.6623 21.4959 22.6907 21.3864C22.7191 21.2768 22.7177 21.1617 22.6866 21.0528L21.9351 18.4227L21.9352 18.4227C22.6029 17.2967 22.9691 16.0175 22.9985 14.7087C23.0279 13.3999 22.7195 12.1056 22.103 10.9507C21.4866 9.79583 20.5829 8.8193 19.4791 8.11535C18.3754 7.4114 17.1088 7.00379 15.8017 6.93185" stroke={light ? '#1B1B26' : 'white'} strokeLinecap="round" strokeLinejoin="round" />
  </svg>


)

export default ChatIcon
