import i18next from 'i18next'
import en from './translations/en.json'
import enUK from './translations/en-uk.json'
import pl from './translations/pl.json'
import de from './translations/de.json'
import es from './translations/es.json'
import fr from './translations/fr.json'
import it from './translations/it.json'
import pt from './translations/pt.json'
import { initReactI18next } from 'react-i18next'



const resources = {
  en: { translation: en },
  'en-GB': { translation: enUK },
  pl: { translation: pl },
  de: { translation: de },
  es: { translation: es },
  fr: { translation: fr },
  it: { translation: it },
  pt: { translation: pt },
}

i18next.use(initReactI18next).init({
  resources,
  lng: localStorage.getItem('lang'),
  fallbackLng: 'en-GB',

})

export default i18next;