import React from "react"

export const highlightSearch = (string, search) => {
  return <>
    {string.split(search)[0]}
    <span style={{ backgroundColor: '#DFD3FF', color: '#6123FF' }}>
      {search}
    </span>
    {string.split(search)[1]}
    {string.split(search)[2] && <span style={{ backgroundColor: '#DFD3FF', color: '#6123FF' }}>
      {search}
    </span>}
    {string.split(search)[2]}
  </>
}

export const SUPPORTED_LANGUAGES = {
  "English(US)": 'en',
  "English(UK)": 'en-GB',
  "Polish": 'pl',
  "French": 'fr',
  "German": 'de',
  "Italian": 'it',
  "Portuguese": 'pt',
  "Spanish": 'es',
}


export const getLangCode = (language) => {
  let defaultCode = 'en-GB'
  if (language == 'English') {
    if (localStorage.getItem('lang') == 'en' || localStorage.getItem('lang') == 'en-GB') {
      return localStorage.getItem('lang')
    } else {
      return 'en-GB'
    }
  }
  if (language in SUPPORTED_LANGUAGES) {
    return SUPPORTED_LANGUAGES[language]
  }
  return defaultCode
}